import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as PackageIcon } from "feather-icons/dist/icons/package.svg";
import { ReactComponent as GlobeIcon } from "feather-icons/dist/icons/globe.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as PouchIcon } from "feather-icons/dist/icons/shopping-bag.svg";
import { ReactComponent as ImageIcon } from "feather-icons/dist/icons/image.svg";




import TeamIllustrationSrc from "images/team-illustration-2.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = tw.div`mt-8 max-w-sm mx-auto md:mx-0`;
const Feature = tw.div`mt-8 flex items-start flex-col md:flex-row`;

const FeatureIconContainer = styled.div`
  ${tw`mx-auto inline-block border border-primary-500 text-center rounded-full p-2 flex-shrink-0`}
  svg {
    ${tw`w-5 h-5 text-primary-500`}
  }
`;

const FeatureText = tw.div`mt-4 md:mt-0 md:ml-4 text-center md:text-left`;
const FeatureHeading = tw.div`font-bold text-lg text-primary-1000`;
const FeatureDescription = tw.div`mt-1 text-sm`;

export default ({
  heading = (
    <>
      Stay organised <span tw="text-primary-500">and</span> inpsired.
    </>
  ),
  description = "Embark on your next adventure with our ultimate travel bundle, designed to keep you stylish and organized wherever you go. This essential kit includes everything the savvy traveler needs, from a sleek passport holder to handy language cards, ensuring a smooth and inspiring journey.",
  features = null,
  textOnLeft = true
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */
  const defaultFeatures = [
    {
      Icon: PackageIcon,
      title: "Passport Wallet",
      description: "Keep your passport stylishly protected with our durable PU Leather Wallet. Includes slots for passport, tickets, cards and SIM cards."
    },
    {
      Icon: TagIcon,
      title: "Luggage tags",
      description: "Personalise your luggage and easily spot it on the carousel with our durable and stylish luggage tags."
    },
    {
      Icon: PouchIcon,
      title: "Travel Pouch",
      description: "Keep your essentials organised and accessible on-the-go with our sleek and versatile travel pouch."
    },
    {
      Icon: ImageIcon,
      title: "Sticker Pack",
      description: "Transform your travel memories into art with out vibrant sticker pack featuring 30 stickers of iconic landmarks from around the globe."
    },
    {
      Icon: GlobeIcon,
      title: "Language Translation Cards",
      description: "Break down language barries effortlessly with our handy translation cards, making basic communication a breeze where you go."
    }
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={TeamIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Features>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <FeatureIconContainer>{<feature.Icon />}</FeatureIconContainer>
                  <FeatureText>
                    <FeatureHeading>{feature.title}</FeatureHeading>
                    <FeatureDescription>{feature.description}</FeatureDescription>
                  </FeatureText>
                </Feature>
              ))}
            </Features>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
