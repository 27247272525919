import React from "react";
import { createRoot } from 'react-dom/client';
import AgencyLandingPage from "./demos/AgencyLandingPage"; // Import AgencyLandingPage
import GlobalStyles from 'styles/GlobalStyles';
import Modal from "react-modal";

Modal.setAppElement("#root");

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <GlobalStyles />
    <AgencyLandingPage />
  </React.StrictMode>
);
